<template>
  <section class="tabs-content personal__content personal__content tabs-content--active">
    <template v-if="!detail">
      <template v-for="(item, index) in items">
        <p :key="index">
          <a
            href="#"
            @click.prevent="showContent(item)"
          >{{ item.NAME }}</a>
        </p>
      </template>
    </template>

    <template v-if="detail">
      <div class="scs-detail">
        <h2>{{ detail.NAME }}</h2>
        <div v-html="detail.PREVIEW_TEXT"></div>
        <a
          class="scs-detail__close"
          @click.prevent="closeContent()"
        ></a>
      </div>
    </template>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Methodology',
  data() {
    return {
      items: [],
      detail: null,
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
  },
  methods: {
    getItemList() {
      this.items = [];

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getMethodologyList');

      axios
        .get(url)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showContent(item) {
      this.detail = item;
    },
    closeContent() {
      this.detail = null;
    },
    noCopy(event) {
      event.clipboardData.setData('text/plain', '');
      event.preventDefault();
    },
  },
  created() {
    this.getItemList();

    document.oncontextmenu = () => false;
    document.addEventListener('copy', this.noCopy);
  },
};
</script>

<style lang="scss" scoped>
  .scs-detail {
    position: relative;

    h2 {
      padding-right: 55px;
    }

    &__close {
      position: absolute;
      top: 7px;
      right: 0;
      width: 40px;
      height: 40px;
      cursor: pointer;
      z-index: 2;

      &:before,
      &:after {
        content: '';
        position: absolute;
        background: #cc3637;
        width: 100%;
        height: 2px;
        top: calc(50% - 1px);
        left: 0;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
</style>
